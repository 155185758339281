import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';
import PatternDark from '../assets/images/patterns/escheresque_ste.png';

import DemonstrationVideo from '../assets/videos/Demonstration.mp4';

import ApplicationContext from '../components/particles/context/ApplicationContext';
import * as ROUTES from '../components/particles/constants/routes';

import Layout from '../components/templates/Default';

const Wrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 32px;
  width: 100%;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;

    text-align: left;
  }

  hr {
    margin: 64px auto;
    width: 100%;

  }

  img,
  picture {
    display: block;
  }

  .ciu_embed {
    a {
      display: block;
    }
  }

  /* IE Fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const InstructionsPage = () => {
  const { state } = useContext(ApplicationContext);

  return (
    <Wrapper mode={state.theme}>
      <h1 className="h4">Demonstrational Video</h1>
      <p>
        The following video is a recording demonstrating the features
        available within the mapping tool.
      </p>
      <video autoPlay controls muted>
        <source src={DemonstrationVideo} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <hr />
      <h2 className="h4">Text Instructions</h2>
      <ol>
        <li>
          Load the application in a modern browser (supports
          IndexedDB).
        </li>
        <li>
          <Link to={ROUTES.SIGN_IN}>Log in to application</Link>
        </li>
        <li>
          <Link to={ROUTES.INSTALL}>
            Navigate to installation page
          </Link>
        </li>
        <li>Upload CSV file with location records</li>
        <li>Submit form to process and create local database</li>
        <li>
          <Link to={ROUTES.HOME}>Load map page</Link> to view visual
          records and filter
        </li>
      </ol>
      <hr />
      <h2 className="h4">Browser Support</h2>
      <p>
        To improve performance, the current mapping tool uses
        IndexedDB (2.0) to locally store CSV records in a browser
        database.
      </p>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/gh/ireade/caniuse-embed/caniuse-embed.min.js"></script>
      </Helmet>
      <p
        className="ciu_embed"
        data-feature="indexeddb2"
        data-periods="future_1,current,past_1,past_2"
        data-accessible-colours="false"
      >
        <a href="http://caniuse.com/#feat=indexeddb2">
          <picture>
            <source
              type="image/webp"
              srcSet="https://res.cloudinary.com/ireaderinokun/image/upload/v1569756140/caniuse-embed/indexeddb2-2019-9-29.webp"
            />
            <source
              type="image/png"
              srcSet="https://res.cloudinary.com/ireaderinokun/image/upload/v1569756140/caniuse-embed/indexeddb2-2019-9-29.png"
            />
            <source
              type="image/jpeg"
              srcSet="https://res.cloudinary.com/ireaderinokun/image/upload/v1569756140/caniuse-embed/indexeddb2-2019-9-29.jpg"
            />
            <img
              src="https://res.cloudinary.com/ireaderinokun/image/upload/v1569756140/caniuse-embed/indexeddb2-2019-9-29.png"
              alt="Data on support for the indexeddb2 feature across the major browsers from caniuse.com"
            />
          </picture>
        </a>
      </p>
    </Wrapper>
  );
};

export default () => (
  <Layout page="signin" align="center">
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Instructions`}
      defer={false}
    />
    <InstructionsPage />
  </Layout>
);
